import React, { useEffect } from "react"

const BrilOnlinePassenEmbed: React.FC = () => {
  useEffect(() => {
    const e = document.createElement("script"), t = document.createElement("link"), n = (new Date).getTime(),
      i = document.createElement("brilonline-widget");
    e.src = "https://paskamer.brilonline.com/js/app.js?id=" + n, e.async = !0, t.href = "https://paskamer.brilonline.com/css/app.css?id=" + n, t.rel = "stylesheet",
      i.setAttribute("api-token", "QIbKi8inhWK2pA4atTSNcqGcuFNb7Y4H"), i.setAttribute("fitting-room", "rami-optiek-leiden")

    document.getElementById("brilonline-widget-container")!.replaceWith(i), document.body.appendChild(e), document.head.appendChild(t)
  }, [])

  return <div id="brilonline-widget-container"></div>
}

export default BrilOnlinePassenEmbed
