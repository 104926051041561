import React from "react"
import { Col, Container, Row } from "reactstrap"
import { Box } from "ticeboxes"
import Layout from "../components/layout/Layout"
import Head from "../components/layout/Head"
import BrilOnlinePassenEmbed from "../components/online-bril-passen/BrilOnlinePassenEmbed"

const contact: React.FC = () => {
  return (
    <>
      <Head
        title="Online Bril Passen"
        description="Pas je bril alvast online op de Eyefashion bij Rami website. "
      ></Head>
      <Layout>
        <Row className="my-4">
          <Col xs="12" clasName="text-center text-md-left">
            <Box>
              <BrilOnlinePassenEmbed />
            </Box>
          </Col>
        </Row>
      </Layout>
    </>
  )
}

export default contact
